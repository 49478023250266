$bootstrap-icons-font-dir: "~node_modules/bootstrap-icons/font/fonts";
//$bootstrap-icons-font-src: url("../../node_modules/bootstrap-icons/font/fonts/bootstrap-icons.woff2") format("woff2"), url("../../node_modules/bootstrap-icons/font/fonts/bootstrap-icons.woff") format("woff");
@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-icons/font/bootstrap-icons";
.wfh-banner img
{
  width: 100%;
}
.nav-bosw-top {
  ul {
    @extend .navbar-nav;
    li 
    {
      @extend .nav-item;
      a {
        @extend .nav-link;
      }
    }
  }
}
body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 20px;
  color: #333;
  background-color: #fff;
  box-sizing: border-box;
  display: block;
  text-size-adjust: 100%;
}
.navbar {
  top: 0;
  border-width: 0 0 1px;
}
.navbar-fixed-top {
  top: 0;
  border-width: 0 0 1px;
}
.navbar-brand img {
  max-height: 100px;
  max-width: 100%;
}
.navbar-fixed-top,
.navbar-fixed-bottom {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030;
}
footer {
  padding-top: 20px;
}
footer,
footer a,
footer a:link,
footer a:visited,
footer a:active,
footer a:focus {
  color: #ffffff;
}
.navbar-nav li {
  float: left;
}
h1, h2, h3, h4, h5, h6
{
  color: #255686;
}
.wfh-fpbox1
{
  h1
  {
    @extend .bi;
    @extend .bi-calendar-check;
  }
}
.wfh-fpbox2
{
  h1
  {
    @extend .bi;
    @extend .bi-compass;
  }
}
.wfh-fpbox3
{
  h1
  {
    @extend .bi;
    @extend .bi-chat-left-dots;
  }
  img 
  {
    width: 2em;
  }
}

.wfh-progress
{
  div 
  {
    position: absolute;
    width: 200px;
    height: 200px;
    max-width: 200px;
    max-height: 200px;
  }
  .wfh-background
  {
    background-image: url("../img/Ribbon.png"); 
    width: 200px;
    height: 200px;
    background-size: cover;
    z-index: 20;  
  }
  .wfh-filled
  {
    offset-anchor: center bottom;
    z-index: 10;
    @extend .mt-auto;
    background-color: purple;
  }
}
